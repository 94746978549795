import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { finalize, catchError } from "rxjs/operators";
import { MessagingService } from '../messaging.service';
import { BUS_MESSAGE_KEY } from '../../constants/message-bus';
import { environment } from "src/environments/environment";
import { FILE_SCAN_URLS } from "../../constants/antivirus-scan-constant";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    activeCalls: number = 0;
    isScanningApi: boolean = false;
    constructor(private messagingService: MessagingService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.messagingService.publish(BUS_MESSAGE_KEY.LOADER, true);
        if(req.headers.get('skip')=='true') {
            this.messagingService.publish(BUS_MESSAGE_KEY.LOADER, false);
        }

        if (
          req.url.includes(FILE_SCAN_URLS.pollingUrl) ||
          (req.url.includes(environment.FILE_SCAN_CONTAINER) &&
          req.method !== "DELETE") ||
          req.url.includes(FILE_SCAN_URLS.sasTokenUrl)
        ) {
          this.isScanningApi = true;
        } else {
          this.isScanningApi = false;
        }
        ++this.activeCalls;

        return next.handle(req).pipe(
          catchError((err) => {
            return throwError(err);
          }),
          finalize(() => {
            --this.activeCalls;
            if (!this.activeCalls && !this.isScanningApi) {
              this.messagingService.publish(BUS_MESSAGE_KEY.LOADER, false);
            }
          })
        );
    }
}
