import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { IDusTableParams } from "../interfaces";

@Injectable({
  providedIn: "root",
})
export class DusService {
  public reason: any = new Subject();
  public updatedRejectionReason: any = new Subject();
  constructor(private http: HttpClient) {}

  /**
   * GetDusTableData: Method returns a data
   *  of dus table for a specific id
   * @param body Object
   *
   * @returns object
   */
  getDusTableData = (body: IDusTableParams) =>
    this.http.post(
      `${environment.API_MICROSERVICE_URL.PROJECT}/dus/dusdetails`,
      body
    );

  /**
   * GetFilterMenu: Method returns a data
   *  of dus filters for a specific id
   * @param body Object
   *
   * @returns object
   */
  getFilterMenu = (id: string | number) => {
    return this.http.get(
      `${environment.API_MICROSERVICE_URL.PROJECT}/dus/dusfilters/${id}`
    );
  };

  /**
   * DownloadDusData: Method returns a file
   *  of dus data
   *
   * @param projectId number
   *
   * @returns file
   */
  downloadDusData(projectId: number) {
    return this.http.get(
      `${environment.API_MICROSERVICE_URL.PROJECT}/dus/download/${projectId}`,
      {
        responseType: "blob",
        observe: "response",
      }
    );
  }
}
