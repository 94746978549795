/***Testcases & Userstories Chart */
export const TESTCASES_CHART_ID = "testcaseTile";
export const USERSTORIES_CHART_ID = "userstoriesTile";
export const USERSTORIES_X_ELEMENT = "status";
export const USERSTORIES_Y_ELEMENT = "percent";
export const TESTCASES_X_ELEMENT = "status";
export const TESTCASES_Y_ELEMENT = "percent";

export enum USERSTORIES_STATUS {
  NOT_STARTED = "Not Started",
  IN_PROGRESS = "In Progress",
  DONE = "Done",
  REWORK = "Rework",
  PO_ACCEPTANCE = "PO Acceptance",
  CANCELLED = "Cancelled",
}

export enum TESTCASES_STATUS {
  NOT_EXPORTED = "Not Exported",
  EXPORTED = "Exported to ALM",
  FAILED = "Failed to Export",
}

export const USERSTORIES_CHART_STYLE = [
  {
    status: USERSTORIES_STATUS.NOT_STARTED,
    color: "#8C95A1",
    margin: "5px",
  },
  {
    status: USERSTORIES_STATUS.IN_PROGRESS,
    color: "#43B02A",
    margin: "5px",
  },
  {
    status: USERSTORIES_STATUS.DONE,
    color: "#00A3E0",
    margin: "5px",
  },
  {
    status: USERSTORIES_STATUS.REWORK,
    color: "#ED8B00",
    margin: "5px",
  },
  {
    status: USERSTORIES_STATUS.PO_ACCEPTANCE,
    color: "#A0DCFF",
    margin: "5px",
  },
  {
    status: USERSTORIES_STATUS.CANCELLED,
    color: "#012169",
    margin: "5px",
  },
];

export const TESTCASES_CHART_STYLE = [
  {
    status: TESTCASES_STATUS.NOT_EXPORTED,
    color: "#9FA7B1",
    margin: "5px",
  },
  {
    status: TESTCASES_STATUS.EXPORTED,
    color: "#2A9FD0",
    margin: "5px",
  },
  {
    status: TESTCASES_STATUS.FAILED,
    color: "#FF5943",
    margin: "5px",
  },
];

/**Methodology Tile */
export enum METHODOLOGY_ACTIVITY_STATUS {
  NOT_STARTED = "Global.Common.Label.NotStarted",
  SKIPPED = "TrainStop.Header.SkippedText",
  COMPLETED = "TrainStop.Header.CompletedText",
}
export enum METHODOLOGY_ACTIVITY_JSONSTATUS {
  NOT_STARTED = "notcompletedCount",
  SKIPPED = "skippedCount",
  COMPLETED = "completedCount",
}

export enum METHODOLOGY_PHASES {
  PREPARE = "Prepare",
  IMAGINE = "Imagine",
  DELIVER = "Deliver",
  RUN = "Run",
}
export const PHASE_DETAILS = [
  {
    sequenceid: 1,
    phaseName: METHODOLOGY_PHASES.PREPARE,
    active: false,
    id: 1,
    phase_id: 9004,
    icon: "/assets/Vector.png",
    barcolor: "#005587",
    lightbarcolor: "#DAE6ED",
    url: "",
    trainstop: [
      {
        seqid: 1,
        icon: "/assets/activities/Sense.png",
      },
      {
        seqid: 2,
        icon: "/assets/activities/Define_Digital_Ambitions.png",
      },
      {
        seqid: 3,
        icon: "/assets/activities/Define_Journey.png",
      },
      {
        seqid: 4,
        icon: "/assets/activities/Establish_Value_Proposition.png",
      },
    ],
  },
  {
    sequenceid: 2,
    phaseName: METHODOLOGY_PHASES.IMAGINE,
    active: false,
    id: 2,
    phase_id: 9001,
    icon: "/assets/Vector.png",
    barcolor: "#0097A9",
    lightbarcolor: "#D8F1F2",
    url: "",
    trainstop: [
      {
        seqid: 1,
        icon: "/assets/activities/Launch_Journey.png",
      },
      {
        seqid: 2,
        icon: "/assets/activities/Define_Advance_Organization.png",
      },
      {
        seqid: 3,
        icon: "/assets/activities/Apply_Design_Thinking.png",
      },
      {
        seqid: 4,
        icon: "/assets/activities/Adapt_Leading_Practices.png",
      },
      {
        seqid: 5,
        icon: "/assets/activities/Refine_User_Stories.png",
      },
    ],
  },
  {
    sequenceid: 3,
    phaseName: METHODOLOGY_PHASES.DELIVER,
    active: false,
    id: 3,
    phase_id: 9000,
    icon: "/assets/Vector.png",
    barcolor: "#BAC700",
    lightbarcolor: "#F1F4CC",
    url: "",
    trainstop: [
      {
        seqid: 1,
        icon: "/assets/activities/Activate_Advance_Organization.png",
      },
      {
        seqid: 2,
        icon: "/assets/activities/Sprint.png",
      },
      {
        seqid: 3,
        icon: "/assets/activities/Validate.png",
      },
      {
        seqid: 4,
        icon: "/assets/activities/Deploy.png",
      },
    ],
  },
  {
    sequenceid: 4,
    phaseName: METHODOLOGY_PHASES.RUN,
    active: false,
    id: 4,
    phase_id: 9002,
    icon: "/assets/Vector.png",
    barcolor: "#26890D",
    lightbarcolor: "#EAF3E7",
    url: "",
    trainstop: [
      {
        seqid: 1,
        icon: "/assets/activities/Initiate.png",
      },
      {
        seqid: 2,
        icon: "/assets/activities/Transition.png",
      },
      {
        seqid: 3,
        icon: "/assets/activities/Stabilize_and_Optimize.png",
      },
      {
        seqid: 4,
        icon: "/assets/activities/Sustain_and_Innovate.png",
      },
    ],
  },
];

export const COMPLETED_ACTIVITY_COUNT_TEXT_COLOR: any = "#59616C";
export const TOTA_ACTIVITYL_COUNT_TEXT_COLOR: any = "#D1D3D6";

export const METHODOLOGY_PHASE_STRUCTURE = [
  {
    id: 1,
    phaseName: METHODOLOGY_PHASES.PREPARE,
  },
  {
    id: 2,
    phaseName: METHODOLOGY_PHASES.IMAGINE,
  },
  {
    id: 3,
    phaseName: METHODOLOGY_PHASES.DELIVER,
  },
  {
    id: 4,
    phaseName: METHODOLOGY_PHASES.RUN,
  },
];
/** Client Details Section */
export const PROJECT_STATUS_SUMMARY_PAGE = {
  projectStatus: "APPROVED",
  isPSGCompleted: "Y",
  colorCode: "#43B02A",
};

// important for summary page.
export const SUMMARY_HEADER_NAV = {
  SCOPE_GENERATOR: "/psg/engagement-details/client-description/",
  PROJECT_DETAILS: "/psg/general-details/engagement/project/",
  PROJECT_SETUP: "/psg/general-details/engagement/project/",
  DELETE_PROJECT: "",
  MANAGE_USERS: "/workspace/my-projects/manage-project/users",
  PROJECT_AMPLIFIER: "/project/summary",
};

export const TILES_CONFIG = [
  { cols: 2, rows: 6 },
  { cols: 2, rows: 3 },
  { cols: 2, rows: 3 },
  { cols: 2, rows: 3 },
  { cols: 2, rows: 3 },
  { cols: 4, rows: 5 },
  { cols: 4, rows: 3 },
  { cols: 2, rows: 3 },
  { cols: 4, rows: 3 },
  { cols: 4, rows: 3 },
  { cols: 8, rows: 3 },
];

export const NEW_TILES_CONFIG = [
  { cols: 2, rows: 5 },
  { cols: 2, rows: 5 },
  { cols: 2, rows: 5 },
  { cols: 2, rows: 5 },
  { cols: 4, rows: 1 },
  { cols: 4, rows: 1 },
  { cols: 4, rows: 1 },
  { cols: 4, rows: 1 },
  { cols: 4, rows: 1 },
];

 export const New_PMO_TILES_CONFIG = [
  { cols: 1, rows: 4 },
  { cols: 1, rows: 1 },
  { cols: 1, rows: 1 },
  { cols: 1, rows: 1 },
];

export const SPRINT_COLORS = [
  "#6DAC73",
  "#33A87E",
  "#3EC6AD",
  "#3EB5C6",
  "#54A3DC",
  "#469BFF",
  "#759CFF",
  "#908DFF",
  "#BB8DF5",
  "#E476AB",
];
export const SPRINT_CAPACITY_LABELS = {
  ScheduleCapacity: "Schedule Capacity",
  PlannedCapacity: "Planned Capacity",
};

export const PROJECT_ROLE = {
  PROJECT_ADMIN: "Project admin",
  PROJECT_USER: "Project user",
};

export const ZERO_COUNT = "00";

export const FEATURE_NAME = { PROJECT_SUMMARY: "Project Summary" };
export const SPRINT_NAME = "sprint";

export const SPRINT_PLAN = {
  SPRINT_PLAN_SUCCESS:
    "Activities.UserStoryLibrary.SprintPlan.SprintPlanSuccess",
  SPRINT_PLAN_SUCCESS_ALL:
    "Activities.UserStoryLibrary.SprintPlan.SprintPlanSuccessAll",
  SPRINT_PLAN_CONFIRMALL_SUCCESS:
    "Activities.UserStoryLibrary.SprintPlan.SprintPlanSectionSuccess",
};

export const SPRINT_CAPACITY = {
  SPRINT_CAPACITY_SUCCESS:
    "Activities.UserStoryLibrary.SprintCapacity.SprintCapacitySuccess",
  SPRINT_CAPACITY_SUCCESS_ALL:
    "GenerateReleasePlan.Popup.SprintCapacity.SprintCapacitySuccessAll",
  NEXT_STEP_TOOLTIP: "Global.Common.Label.NextStepTooltipText",
  PREVIOUS_STEP_TOOLTIP: "Global.Common.Label.PreviousStepTooltipText",
};

export const DEFINEROADMAP_ACTION = {
  EXPAND_COLLAPSE_ALL:
    "Activities.UserStoryLibrary.DefineRoadmap.DefineroadmapAction.ExpandCollapseAll",
};

export const ASCEND_MODES = {
  EXPLORE_MODE: "EXPLORE",
  PROJECT_MODE: "PROJECT",
};

export const PROJECT_STATUS = "Global.Common.ToolTip.Active";

export const PROJEECT_SUMMARY_DOWNLOAD_REPORTS_URL =
  "/ReportsGeneration/RequestReport";


export const DOWNLOAD_REPORTS = {
  ProjectDeliverablesReport: "Project Deliverables Report",
  ProjectAmplifiersReport: "Project Amplifiers Report",
  ProjectToolsReport: "Project Tools Report",
};

export const projectSyncStatus = {
  reportsWillBeAvailableaAfterSync: "Reports will be available after sync",
};