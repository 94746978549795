import { SAMPLE_FILE, TEMPLATE_FILE } from "@projects/taxonomy-documents/src/app/taxonomy-documents/shared/taxonomy-constants";
import { ERROR_MSG, SCAN_RESULT_TYPE } from "src/app/shared/constants/antivirus-scan-constant";

/**
 * fileScanResult: Subscribes to the antivirus scan service to monitor the loading state and scan results,
 * updating the component's state accordingly.
 *
 * @param componentRef - The reference of the component where this function called.
 */
export const fileScanResult = (componentRef) => {
  const isLoading = componentRef.antivirusScanService.isLoading.subscribe({
    next: (data) => {
      componentRef.isLoading = data;
    },
  });

  const scanResult =
    componentRef.antivirusScanService.scanResult.subscribe({
      next: (data) => {
        const scanResult = data;

        if (scanResult === ERROR_MSG.maliciousEndResult) {
          componentRef.isDisabled = true;
          componentRef.errorMessage = ERROR_MSG.maliciousEndResult;
          componentRef.hasScanError = true;

          if (componentRef.dropArea === TEMPLATE_FILE) {
            componentRef.errorMsgObj.templateFile = ERROR_MSG.maliciousEndResult
          }

          if (componentRef.dropArea === SAMPLE_FILE) {
            componentRef.errorMsgObj.sampleFile = ERROR_MSG.maliciousEndResult
          }
          
        } else {
          componentRef.errorMessage = "";
          componentRef.hasScanError = false;

          if (componentRef.dropArea === TEMPLATE_FILE) {
            componentRef.errorMsgObj.templateFile = ''
          }

          if (componentRef.dropArea === SAMPLE_FILE) {
            componentRef.errorMsgObj.sampleFile = ''
          }

        }

        if (scanResult && componentRef.callUploadAPI) {
          componentRef.callUploadAPI();
        }
      },
    });

    if (componentRef?.allSubscriptions) {
      componentRef.allSubscriptions.push(isLoading, scanResult);
    } else {
      componentRef.subscription.push(isLoading, scanResult);
    }
};
