<div class="dialog-container">
    <div class="header">
            <div class="title">{{data?.title | translate}}</div>
            <button mat-icon-button class="close-icon" (click)="cancel()">
                <mat-icon >close</mat-icon>
            </button>
    </div>
    <div class="description">
      <p>{{ 'ListProject.ManageUsers.CommonUploadPopup.UploadInstructions' | translate:{ displaySelectedUserType: displaySelectedUserType } }}</p>
    </div>
  <div class="roleUserAdmin" [ngStyle]="{'display': !data?.domainData?.domainNames?.length || data?.featureConfigurationObj != 'true' ? 'none' : ''}">
        <mat-button-toggle-group class="btnUserAdmin" #group="matButtonToggleGroup" [(value)]="selectedUser"
          (change)="onValChange(group.value)">
          <mat-button-toggle class="buttons" [value]="internalUser">{{'ListProject.ManageUsers.Common.Button.InternalRole' | translate}}</mat-button-toggle>
          <mat-button-toggle *ngIf="data?.domainData?.isExternalUserEnabled" class="buttons" [value]="externalUser">{{'ListProject.ManageUsers.Common.Button.ExternalRole' | translate}}</mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <mat-dialog-content class="dialog-content">
        <div class="upload-file">
            {{data?.message | translate}}
        </div>
        <button class="download-template" (click)="downloadTemplate()">

            <img src="assets/download-template.svg"
                alt="" />
          {{'ListProject.ManageUsers.Common.Label.DownloadExcelTemplate' | translate}}</button>

    </mat-dialog-content>

    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
        (onFileLeave)="fileLeave($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <span>
            <span>{{'ListProject.ManageUsers.Common.Label.DropFiles' | translate}}&nbsp;</span>
            <a class="browse-link" type="link/button" (click)="openFileSelector()">{{'Global.Common.Button.Browse' | translate}}</a>
        </span>
            <span>{{'ListProject.ManageUsers.Common.Label.FileReplacementWarning' | translate}}</span>
        </ng-template>
    </ngx-file-drop>
    <div class="supported-text">
      {{'ListProject.ManageUsers.Common.Label.SupportedFileFormats' | translate}}
    </div>

    <div *ngFor="let item of files; let i=index" class="supported-text row pt-sm-1">
        <div class="col-1 errored" *ngIf="errorText">
            <span class="material-icons"> warning </span>
        </div>
        <div class="col-1 px-0 col1-cust" *ngIf="!errorText && !disabled">
            <img src="assets/psg/blu_tick.png" alt="file is ready to upload">
        </div>
        <div class="path px-0 col-8">
            {{ item.relativePath }}
        </div>
        <div class="col-2 pipes">
            {{ fileSize }}
        </div>
        <div class="col-1">
            <img class="file-close" (click)="removeFile()" src="assets/close-icon.png" />
        </div>
    </div>
    <div class="mb-sm-4 pb-sm-2" *ngIf="!(files.length == 0) && !errorText">
        <mat-progress-bar [ngClass]="{'half': progressValue == 50, 'full': progressValue == 100 }"
            [value]="progressValue" max="100" color="#FF0000" class="w-100"></mat-progress-bar>
    </div>
    <div class="mb-sm-4 error-block" *ngIf="errorText">
        <hr class="errorLine" />
        <div class="warning">
          {{'ListProject.ManageUsers.Common.Label.InvalidFileFormat' | translate}}
        </div>
    </div>
    <span class="mb-sm-4 error-block warning" *ngIf="hasScanError">
        {{ errorMessage | translate }}
    </span>

    <mat-divider></mat-divider>
    <div class="float-end">
        <mat-dialog-actions>
            <button *ngIf="data?.secondaryButtonText" class="mat-raised-button"
                (click)="cancel()">{{data?.secondaryButtonText | translate}}</button>
            <button *ngIf="data?.primaryButtonText" class="mat-raised-button mat-primary ms-3" (click)="uploadFile()"
                [disabled]="isDisabled || isLoading">{{data?.primaryButtonText | translate}}</button>
        </mat-dialog-actions>
    </div>
</div>
