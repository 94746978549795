export const ERROR_MSG = {
  uploadFileErrorMsg: "uploadFileErrorMsg",
  sasURLErrorMsg: "sasURLErrorMsg",
  maliciousFileStatusError: "maliciousFileStatusError",
  maliciousEndResult: "maliciousEndResult",
  sasTokenError: "sasTokenError",
};

export const SCAN_RESULT_TYPE = {
  noThreatsFound: "No threats found",
  malicious: "Malicious",
};

export const POLLING_API_URL = "/File/FileScanLogEntry";

export const POLLING_DURATION = 2000;

export const FILE_SCAN_URLS = {
  pollingUrl: "FileScanLogEntry",
  sasTokenUrl: "GetSASTokenAndCreateUploadRow",
};

export const SAS_URL = "/File/GetSASTokenAndCreateUploadRow";
