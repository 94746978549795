import { Component, OnInit, OnDestroy, HostListener, Inject } from "@angular/core";
import { MessagingService } from "./shared/services/messaging.service";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { BUS_MESSAGE_KEY } from "./shared/constants/message-bus";
import { AuthenticationService } from "./shared/services/authentication.service";
import { Router, NavigationStart, NavigationEnd, RouterEvent } from "@angular/router";
import { SharedService } from "./shared/services/shared.service";
import { DOCUMENT } from "@angular/common";
import { AdobeAnalyticsService } from "./adobe-analytics.service";
import { User } from "./shared/constants/ascend-user-info";
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LANGUAGE } from './shared/constants/language-constant';

import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus, EventMessage, EventType } from '@azure/msal-browser';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  showLoader: BehaviorSubject<boolean>;
  showFileScanLoader: BehaviorSubject<boolean>;
  userData: BehaviorSubject<boolean>;
  langPreference: BehaviorSubject<boolean>;

  islogoutscreen: boolean = false;
  ispsg: boolean = false;
  isMaturityAssessment: boolean = false;
  isClientSurvey: boolean = false;
  currentroute: string = "";
  isTrainstop = false;
  isAmplifier = false;
  isContentRepo = false;
  isAuditLog: boolean = false;
  isExternalSignup: boolean = false;
  isLanguageSettings: boolean = false;
  hideNavigationBar = false;
  private subscriptions: Subscription[] = [];
  loginDisplay = false;


  isWindowLoaded: Boolean = false;
  private readonly _destroying$ = new Subject<void>();


  @HostListener('window:load')
  onLoad() {
    this.isWindowLoaded = true;
    console.log('Window Loaded');
  }
  userDataObj: User;
  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    @Inject(DOCUMENT) private document: Document,
    public router: Router,
    private messagingService: MessagingService,
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private adobeAnalyticService: AdobeAnalyticsService,
    private translate: TranslateService,
  ) {
    this.messagingService.publish(BUS_MESSAGE_KEY.LOADER, true);
    this.currentroute = router.url;
    if (this.currentroute != "/logout" && !this.isClientSurvey) {
      this.handleMsalSubscriptions();
    }
    router.events.pipe(
      filter((event) => (event instanceof NavigationStart || event instanceof NavigationEnd))
    ).subscribe((event: NavigationStart | NavigationEnd) => {
      if (event instanceof NavigationStart) {
        this.isMaturityAssessment = event.url.includes('maturity-assessment');
        if (this.isMaturityAssessment) {
          this.loadStyle('ma-styles.css');
        }
      } else {
        this.ispsg = (event.url.includes("/psg/")) ? true : false;
        this.isTrainstop = (event.url.indexOf('/project/') === -1 && event.url.includes('/trainstops/')) ? true : false;
        this.isAmplifier = (event.url.indexOf('/amplifier-exchange') !== -1) ? true : false;
        this.isContentRepo = (event.url.indexOf('/content') !== -1) ? true : false;
        this.isAuditLog = (event.url.indexOf('/audit-log') !== -1) ? true : false;
        this.isExternalSignup = (event.url.indexOf('/external-user-signup') !== -1) ? true : false;
        this.isLanguageSettings = (event.url.indexOf('/language-settings') !== -1) ? true : false;
      }
    });


  }

  ngOnInit() {
    this.subscriptions.push(
      this.sharedService.getHideNavigationBar().subscribe(data => {
        this.hideNavigationBar = data;
      }));
  }

  initialize() {
    this.setLoginDisplay();
    this.subscriptions.push(
      this.sharedService.getPreferredLanguage().subscribe((result: any) => {
        let lang = DEFAULT_LANGUAGE;
        if (result.data.preferredLanguage && result.data.enabledFlag) {
          lang = result.data.preferredLanguage;
          this.sharedService.setCurrentLanguage(lang?.languageId);
        }
        this.translate.use(lang.locale);
        this.messagingService.publish(BUS_MESSAGE_KEY.LANG_PREFERENCE, { lang, enabledFlag: result.data.enabledFlag, languages: result.data.supportedLanguages });
      })
    )

    this.langPreference = this.messagingService.getBus(BUS_MESSAGE_KEY.LANG_PREFERENCE);
    if (window.location.href.includes("logout")) this.islogoutscreen = true;
    if (window.location.href.includes("/client/survey")) this.isClientSurvey = true;

    this.showLoader = this.messagingService.getBus(BUS_MESSAGE_KEY.LOADER);
    this.showFileScanLoader = this.messagingService.getBus(BUS_MESSAGE_KEY.ANTI_VIRUS_SCAN_LOADER);
    this.userData = this.messagingService.getBus(BUS_MESSAGE_KEY.USER_DETAILS);

    this.messagingService.publish(BUS_MESSAGE_KEY.LOADER, false);
  }

  handleMsalSubscriptions() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => {
          return msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
        }
        ),
      )
      .subscribe((result: EventMessage) => {
        !this.loginDisplay && this.authService.initializeAuth();
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => {
          return status === InteractionStatus.None
        }),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.authService.initializeAuth();
        this.initialize();
      })
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  @HostListener('click', ['$event.target']) onClick(event) {
    this.messagingService.subscribe(BUS_MESSAGE_KEY.USER_DETAILS, (data) => {
      this.userDataObj = data;
      this.userDataObj.userId = data?.ssoUser?.userPrincipalName ?? data.userId;
    })
    if (event.outerText != null && event.outerText != '' && event.outerText != undefined && this.userDataObj.userId != null) {
      this.adobeAnalyticService.setApplicationName("Ascend");
      this.adobeAnalyticService.setPageName(event.url);
      this.adobeAnalyticService.setUserId(this.userDataObj.userId.split('@')[0].toString());
      this.adobeAnalyticService.setButtonName(event.outerText);
      this.adobeAnalyticService.trackNavigationEventAction();
    }
  }
  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'ma-theme'
    ) as HTMLLinkElement;
    if (!themeLink) {
      const style = this.document.createElement('link');
      style.id = 'ma-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.authService?.cleanListerners();
    this.subscriptions?.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
