export const MethodContentText = 'AdminModule.TaxonomyDocuments.Label.MethodContentText';
export const ReloadUploadtext = 'AdminModule.TaxonomyDocuments.Label.ReloadUploadtext'

export const ContentPhaseStopMapping = [
    'Prepare > Define journey > Determine initial development scope',
    'Imagine > Apply design thinking > Identify personas',
    'Imagine > Apply design thinking > Document journey maps',
    'Imagine > Adapt leading practices > Define foundational design',
    'Imagine > Adapt leading practices > Prepare for design confirmation workshop',
    'Imagine > Adapt leading practices > Define baseline configuration',
    'Imagine > Refine User Stories > Define foundational design',
    'Prepare > Define journey > Determine initial development scope',
    'Prepare > Define journey > Determine initial development scope',
]

export const ACTION = {EDITDOCUMENT : 'edit', ADDNEWDOCUMENT : 'addnewdocument'};
export const DOC_NAME_REGEX = /^[a-zA-Z0-9.\-_\&\/ ]+$/;
export const EXCEPTION_TABCODES = ['PROCESS_FLOWS'];

export const TEMPLATE_FILE = "templateFile";
export const SAMPLE_FILE = "sampleFile";