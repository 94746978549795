<section class="banner-page">
    <section class="banner">
        <div class="breadcrumb">
            <div class="main-container">
                <div class="column-1">
                  <div class="d-flex align-items-center">
                    <button id="btn-back-nav" (click)="goToPreviousPage()">
                        <span class="tooltipbox">{{ 'Global.Common.Label.BackToProjectSummary' | translate }}</span>
                    </button>
                    <span id="vert_line"><img src="assets/psg/Rectangle-3200.svg" alt="" /></span>
                    <div class="title-container d-flex flex-column">
                        <span class="title" >
                            {{ title | translate }}
                        </span>
                        <span *ngIf="!isSearch" class="project-dus-title">{{projectGlobalInfo?.projectName}}</span>
                    </div>
                  
                  </div>

                </div>
								<div class="column-2">
									<button *ngIf="!isSearch" class="download-button d-flex col-gap-2" mat-button (click)="downloadDusData()">
										<img src="assets/psg/download.svg" alt="download-button" /> <span>
											Download DUS
										</span>
									</button>
                    <h3 class="project-title" *ngIf="projectId != 0 && isSearch">{{projectGlobalInfo?.projectName}}</h3>
                    <span class="pipeSearchIcon"></span>
                    
                    <button (click)="showSearchInputBox()" class="searchIcon" *ngIf="!showDocInputSearch && isSearch">
                        <span class="tooltiptext">{{ 'Global.Common.Label.Search' | translate }}</span>
                    </button>
                    <div *ngIf="showDocInputSearch && isSearch" class="input-search">
                        <search-document
                        (selectDocSearchEvent)="selectSearchEvent($event)"
                        (closeSearchEvent)="closeSearchEvent($event)"
                        [showDocInputSearch]="showDocInputSearch"
                        [searchDocumentData]="documentData"
                        [selectedOption]="searchParams"
                        [placeholderText]="searchInputPlaceholder"
                        [noData]="noSearchResult"
                        [projectId] ="projectId"
                        >

                        </search-document>

                      </div>
                    
                     <div *ngIf="showNavigator && projectId !== 0">
                        <app-quick-navigator [projectinfo]="currentProjectInstance" [projectId]="projectId" [currentScreenName]="currentScreenName"></app-quick-navigator>
                     </div> 

                </div>


            </div>
        </div>
    </section>
</section>
