import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { BaseService } from '@projects/shared-lib/src/public-api';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class IIDRService {
  stopsData: any;
  updateTrainData = new Subject<any>();
  private trainStopURL: string = `${environment.API_MICROSERVICE_URL?.PROJECT}/trainstop`;
  private trainStopMethodURL: string = `${environment.API_MICROSERVICE_URL?.METHOD}/explore/trainstop`;
  private packageContentMSUrl: string = `${environment.API_MICROSERVICE_URL?.PACKAGE_CONTENT}`;
  private previousUrl: string;
  private currentUrl: string;
  public tabData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public setIsDataExplore = new Subject();

  constructor(private http: HttpClient, private router: Router, private baseService:BaseService) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  setTrainStopData(stopData, isPackageMethodExist) {
    let obj = {
      stopData: stopData,
      isPackageMethodExist: isPackageMethodExist
    }
    this.updateTrainData.next(obj);
  }

  getTrainStopDataOnCardLayout(projectId, packageId, funcId, trainStopURL = this.trainStopURL): Observable<any> {
      let url = `${trainStopURL}/${projectId}/${packageId}/${funcId}`;
      return this.http.get<any>(url);
  }

  getTrainStopData(projectId, packageId, funcId, languageId, disciplineIds: number[] = [], subFunctionId = null): Observable<any> {
    if (projectId == 0) {
      let url = `${this.trainStopMethodURL}/${packageId}/${funcId}`;
      if(subFunctionId)
        url += `/${subFunctionId}`;
      if(languageId)
        url += `/${languageId}`;
      if(disciplineIds.length)
        url += `/?disciplineIds=[${disciplineIds}]`;
      return this.http.get<any>(url)
    }
    else {
      let url = `${this.trainStopURL}/${projectId}/${packageId}/${funcId}`;
      if(subFunctionId)
        url += `/${subFunctionId}`;
      if(disciplineIds.length)
        url += `/?disciplineIds=[${disciplineIds}]`;
      return this.http.get<any>(url);
    }
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }
  public getCurrentUrl() {
    return this.currentUrl;
  }

  getTab(tabVal: any): void {
    this.tabData.next(tabVal);
  }
  setTab(): Observable<any> {
    return this.tabData.asObservable();
  }

  getIndustryPrints() {
    let url = `${this.packageContentMSUrl}/external-api/getIndustryPrint`;

    return this.http.get<any>(url)
  }

  getParentPackageDetails(parentPackageId: number) {
    let url = `${environment.API_MICROSERVICE_URL?.PACKAGE_CONTENT}/external-api/packages?parentPackageId=${parentPackageId}`;
    return this.http.get<any>(url)
  }

  formatIndustryPrints(data) {
    let industryPrints = [];

    industryPrints = data.filter((i) => i.enabled_flag)
      .map((i) => {
        return {
          id: i.industry_print_id,
          goldenClientName: i.golden_client_name,
          engagementId: i.engagement_id,
          parentPackageId: i.parent_package_id
        }
    });

    return industryPrints;
  }

  getConstructIds(projectId: number): Observable<any> {
    return this.baseService.get(`/project/pmg-construct-ids/${projectId}`, environment.API_MICROSERVICE_URL?.PROJECT)
  }

  getPreviewData(packageId, languageId, methodData, projectName){
    const optionIds = methodData.map((options) => { return options.selectedOptionId });
    let payload = {
      isPMGProject: true,
      reportName: projectName,
      optionIds
    };
    return this.baseService.post(`/pmg/preview/${packageId}/${languageId}`, payload,environment.API_MICROSERVICE_URL?.METHOD)
  }

  downloadProjectReport(packageMethodId: number, payload: any): void {
    this.baseService.getBlobAsJson(`pmg/download/${packageMethodId}`, payload, environment.API_MICROSERVICE_URL?.METHOD)
      .subscribe({
        next: (response: any) => {
          const dataHeaders = response.headers.get('content-disposition');
          const fileName = JSON.parse(dataHeaders?.split('=')[1]);
          const type = response.headers.get('content-type');
          const blob = new Blob([response.body], { type });
          saveAs(blob, fileName);
        }
      });
  }
  
  getProjectInfo(projectId: any, params?: any,languageId=1) {
    return this.http.get(environment.API_MICROSERVICE_URL?.PROJECT + `/project/` + projectId + `/${languageId}`, { params });
  }
}
