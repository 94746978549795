export enum BUS_MESSAGE_KEY {
  LOADER        = 'loader',
  GLOBAL_FILTER = 'global_filter',
  USER_DETAILS  = 'user_details',
  STOP_NAME     = 'stop_name',
  FILTER_CHANGED = "filter_changed",
  IIDR_FILTER   = 'iidr_filter',
  SITEMAP_FILTER = 'sitemap_filter',
  FUNCTION_PACKAGE = 'function_package',
  SPRINTS = 'sprint_tile',
  USER_STORIES = 'user_stories',
  TEST_CASES = 'test_cases',
  LANG_PREFERENCE = 'lang_preference',
  TOOLS_DATA = 'tools_data',
  RELEASE_BANNER = 'release_banner',
  ANTI_VIRUS_SCAN_LOADER = "antivirus_scan_loader"
}
