export class ProjectGlobalInfoModel {
    userId: String;
    projectId: String;
    role: String;
    viewMode: String;
    uniqueId: String;
    userroleId: String;
    offerings?: String;
    projectName?:String;
    clientName?:String;
    clientUrl?:String;
    managerName?:String;
    packageName?:String;
    displayName?:String;
    offeringName?:String;
    projectParentPackageId?:String;
    offeringId? : number;
    accessObjects?:[{ attributeName: string, objectValue: string, permission: String }];
    userRoleInfo?:UserRoleAssociationDetails;
}

export class UserRoleAssociationDetails {
    role: { id: number, name: String, isPackageAdmin: Boolean };
    menu: Array<{ menuName: String, menuId: number }>;
    roleSubset?: Array<{ name: String, id: number, isPackageAdmin: Boolean }>
    package?: [{ id: number, name: String }];
    accessObjects?: [{ attributeName: string, objectValue: string, permission: String }];
    userEmail: String;
    englishLanguage?: { id: number, name: String, actv_flg: Boolean, is_package_admin: Boolean};
}
