import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { AcknowledgeModalComponent } from '../../acknowledge-modal/acknowledge-modal.component';
import { MyProjectsService } from '../../my-projects.service';
import { INTERNAL_USER, EXTERNAL_USER, WARNING_MESSAGE, WARNING_HEADER_TITLE, WARNING_PRIMARY_BUTTON, WARNING_SECONDARY_BUTTON } from '../../utility/constants';
import {TranslateService} from '@ngx-translate/core';
import { AntivirusScanService } from 'src/app/shared/services/antivirus-scan.service';
import { fileScanResult } from 'src/app/shared/utils/scan-result-util';
import { IScanErrorResponse } from 'src/app/shared/interfaces/scan-interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-common-upload-popup',
  templateUrl: './common-upload-popup.component.html',
  styleUrls: ['./common-upload-popup.component.scss']
})
export class CommonUploadPopupComponent implements OnInit {
  public files: NgxFileDropEntry[] = [];
  progressValue = 0;
  errorText = false;
  isDisabled = true;
  fileSize: string;
  offering_id: any;
  tab: string;
  selectedUser: string = INTERNAL_USER;
  internalUser: string = INTERNAL_USER;
  externalUser: string = EXTERNAL_USER;
  displaySelectedUserType: string = this.translate.instant('ListProject.ManageUsers.Common.Label.Internal');
  errorMessage: string = "";
  hasScanError: boolean = false;
  isLoading: boolean = false;
  scanResult: string;
  subscription: Subscription[] = [];
  
  constructor(
    private dialogRef: MatDialogRef<CommonUploadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private myProjectsService: MyProjectsService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private antivirusScanService: AntivirusScanService
  ) { }

  ngOnInit(): void {
    this.errorMessage = "";
    this.hasScanError = false;
    fileScanResult(this);
  }

  public fileOver(event) { }

  public fileLeave(event) { }

  public dropped(files: NgxFileDropEntry[]) {
    if (files.length > 1) {
      this.errorText = true;
      this.isDisabled = true;
    } else {
      if (this.files.length) this.antivirusScanService.deleteBlobFile().subscribe();
      this.files = files;
      for (const droppedFile of files) {
        if (
          droppedFile.fileEntry.isFile &&
          this.isFileAllowed(droppedFile.fileEntry.name)
        ) {
          this.errorText = false;
          this.isDisabled = false;
          this.progressValue = 50;
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            this.antivirusScanService.isLoading.next(true);
            this.antivirusScanService
              .scanVirus(file)
              .subscribe((response: IScanErrorResponse) => {
                const { error, errorMsg } = response;
                if (error) {
                  this.isDisabled = true;
                  this.errorMessage = errorMsg;
                  this.hasScanError = true;
                } else {
                  this.errorMessage = "";
                  this.hasScanError = false;
                }
                this.antivirusScanService.getPollingData().subscribe();
              });
            this.fileSize = this.fileSizeCalculation(file.size);
            setTimeout(() => {
              this.progressValue = 100;
            }, 100);
          });
        } else {
          this.errorText = true;
          this.isDisabled = true;
        }
      }
    }
  }

  removeFile() {
    this.files = [];
    this.errorText = false;
    this.isDisabled = true;
    this.errorMessage = "";
    if (!this.hasScanError) this.antivirusScanService.deleteBlobFile().subscribe();
    this.hasScanError = false;
  }

  isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const allowedFiles = ['.xls', '.xlsx'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }

  fileSizeCalculation(size: number): string {
    let sizeinKb = Math.round((size / 1024 + Number.EPSILON) * 100) / 100;
    let sizeinMb = Math.round((size / 1048576 + Number.EPSILON) * 100) / 100;
    if (sizeinKb < 500) {
      return sizeinKb + 'KB';
    } else return sizeinMb + 'MB';
  }

  close() {
    this.dialogRef.close();
  }

  cancel() {
    this.errorMessage = "";
    if (!this.hasScanError) this.antivirusScanService.deleteBlobFile()?.subscribe();
    this.hasScanError = false;
    this.dialogRef.close();
  }

  uploadFile() {
    const uploadId = this.antivirusScanService.getUploadId();
    const url = `project/upload/${this.selectedUser}/${this.data.projectId}`;
    
    this.myProjectsService.uploadFileData(url, {uploadId: uploadId}).subscribe(
      (res: any) => {
        this.dialogRef.close(true);
      },
      (err) => {
        this.dialogRef.close(false);
      });
  }

  downloadTemplate() {
    this.myProjectsService
      .downloadTemplate(this.selectedUser)
      .subscribe((data) => {
        this.myProjectsService.createFileNameAndSave(data);
      });
  }

  onValChange(val) {
    let condition = (val == INTERNAL_USER)
    this.displaySelectedUserType = condition ? this.translate.instant('ListProject.ManageUsers.Common.Label.Internal') : this.translate.instant('ListProject.ManageUsers.Common.Label.External');
    if(this.files.length) {
      this.showWarningPopUp(val, condition);
    }
  }

  showWarningPopUp(val, condition) {
    let acknowledgeModal = this.dialog.open(AcknowledgeModalComponent,{
      width: '500px',
      data: {
        primaryHeader: `${this.displaySelectedUserType} ` + this.translate.instant(WARNING_HEADER_TITLE),
        bodyMessage: WARNING_MESSAGE,
        primaryButton: WARNING_PRIMARY_BUTTON,
        secondaryButton: WARNING_SECONDARY_BUTTON
      }
    })
    acknowledgeModal.afterClosed().subscribe(response => {
      if(response) {
        this.removeFile();
        this.selectedUser = val;
      }
      else {
        this.selectedUser = condition ? EXTERNAL_USER : INTERNAL_USER;
        this.displaySelectedUserType = condition ? this.translate.instant('ListProject.ManageUsers.Common.Label.External') : this.translate.instant('ListProject.ManageUsers.Common.Label.Internal');
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription?.forEach((sub) => sub.unsubscribe());
    this.antivirusScanService.scanResult.next("")
  }
}
