import { Injectable } from "@angular/core";
import { MessagingService } from "./shared/services/messaging.service";
import { Subscription } from "rxjs";
import { BUS_MESSAGE_KEY } from "src/app/shared/constants/message-bus";
import { User } from "./shared/constants/ascend-user-info";
import { DELOITTE_USER_DOMAIN, EXTERNAL_USER } from "./shared/constants/external-user-constants";
interface PageInfo {
  applicationName: string;
  pageName: string;
  userID: string;
  buttonName: string;
  languages: string;
}

interface AnalyticsDataLayer {
  pageInfo: PageInfo;
}

@Injectable({ providedIn: "root" })
export class AdobeAnalyticsService {
  private subscriptions: Subscription[] = [];
  userData: User;
  private w: {
    analyticsDataLayer: AnalyticsDataLayer;

    _satellite: {
      track: (event: string) => {};
    };
  };

  private get pageInfo(): PageInfo {
    return this.w?.analyticsDataLayer && this.w?.analyticsDataLayer.pageInfo;
  }

  constructor(private messagingBus: MessagingService) {
    this.w = window as any;
  }

  setUserId(id: string) {
    if (this.pageInfo) {
      this.subscriptions.push(
        this.messagingBus.subscribe(BUS_MESSAGE_KEY.USER_DETAILS, (data) => {
          this.userData = data;
          id = this.userData.userId =
            data?.ssoUser?.userPrincipalName ?? data.userId;
        })
      );
      const [user, domain] = id.split("@");
      this.pageInfo.userID = domain === DELOITTE_USER_DOMAIN ? user : EXTERNAL_USER;
    }
  }

  /**
   * SetLanguageName: method will be used to set language Name
   * 
   * @param {string} language
   */
  setLanguageName = (language: string) => {
    if (this.pageInfo) this.pageInfo.languages = language;
  }

  setPageName(pageName: string) {
    if (this.pageInfo) {
      this.pageInfo.pageName = pageName;
    }
  }
  setApplicationName(applicationName: string) {
    if (this.pageInfo) {
      this.pageInfo.applicationName = applicationName;
    }
  }
  setButtonName(buttonName: string) {
    if (this.pageInfo) {
      this.pageInfo.buttonName = buttonName;
    }
  }
  trackNavigationEvent() {
    if (this.w && this.w?._satellite && this.w?._satellite.track) {
      this.w._satellite.track("pageView");
    }
  }
  trackNavigationEventAction() {
    if (this.w && this.w?._satellite && this.w?._satellite.track) {
      this.w._satellite.track("buttonClicks");
    }
  }

  /**
   * TrackLanguageChangeAction: method will be used to track user language change event
   */
  trackLanguageChangeAction = () => {
    if (this.w && this.w?._satellite && this.w?._satellite.track) 
      this.w._satellite.track("languages");
  }
}
